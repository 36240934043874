import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslate } from '../config/translateContext';
import AuthContext from '../config/AuthContext';
import axiosInstance from '../config/axiosConfig';
const stripePromise = loadStripe('pk_live_51PSwQGP0Uqj7v8hTCMUmy3DxoIhgpUOzPjB2w51RzNr5TpPaPhpuV4usVJX39Vpe7kZFhjFxlHyuHPjB9oWyeFeb00XwKc6gaW');

const SubscriptionDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [subscription, setSubscription] = useState(null);
  const [mySub, setMySub] = useState(null);
  const { isTranslated, setIsTranslated } = useTranslate();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const { isAuthenticated } = useContext(AuthContext);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (!loading && !isAuthenticated) {
      navigate('/login');
    }
  }, [loading, isAuthenticated, navigate]);

  useEffect(() => {
    if (!isAuthenticated) return;
    
    console.log('Fetching subscription data...');
    const token = localStorage.getItem('access_token');
    axiosInstance.get(`/subscription/${id}/`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(response => {
      const { subscription, is_my_subscription } = response.data;
      setSubscription(subscription);
      setMySub(is_my_subscription);
      console.log('Subscription data:', response.data);
    })
    .catch(error => {
      console.error('Error fetching subscription:', error);
    });
  }, [id, isAuthenticated]);

  const handleCheckout = async () => {
    const stripe = await stripePromise;
    const token = localStorage.getItem('access_token');

    try {
      const response = await axiosInstance.post(`/subscription/${id}/`, {
        action: 'create_checkout_session'
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });

      const sessionId = response.data.sessionId;
      const result = await stripe.redirectToCheckout({ sessionId });

      if (result.error) {
        console.error('Error redirecting to checkout:', result.error.message);
      } else {
        console.log('Redirecting to checkout:', result);
        navigate('/payment-success');
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };

  const handleDeactivate = async () => {
    console.log('Deactivation function called');  // Logowanie na początku funkcji
    setLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        setError('User not authenticated');
        setLoading(false);
        return;
      }
  
      console.log('Token:', token);  // Logowanie tokena
  
      const response = await axiosInstance.post(`/deactivate_subscription/`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      console.log('Response:', response);  // Logowanie odpowiedzi z serwera

      if (response.status === 200) {
        setSuccess(true);
      } else {
        setError('Deactivation failed');
      }
      setLoading(false);
    } catch (error) {
      if (error.response) {
        console.error('Error response:', error.response);
        setError(`Deactivation failed: ${error.response.data.error}`);
      } else if (error.request) {
        console.error('Error request:', error.request);
        setError('No response received from server');
      } else {
        console.error('Error message:', error.message);
        setError(`Deactivation failed: ${error.message}`);
      }
      setLoading(false);
    }
  };








useEffect(() => {
  console.log('mySub updated:', mySub);
  console.log('subscription updated:', subscription);
}, [mySub, subscription]);

  if (!isAuthenticated) {
    return <h1>Musisz być zalogowany</h1>;
  }

  if (!subscription) {
    return (
      <div className='w-100 d-flex justify-content-center h-100 ontop position-fixed t-0 cloude'>
        <img src='/img/logo.png' className='rotating-object align-self-center loader' alt="Loader" />
      </div>
    );
  }

  return (
    <div className='container vh10'>
    <h1>{subscription.subscription_type.name }</h1>
        <div
      dangerouslySetInnerHTML={{
        __html: isTranslated ? subscription.description_de : subscription.description
      }}
    />

 {(!mySub || mySub.id !== subscription.id) ? (
      <button onClick={handleCheckout} className='btn btn-primary'>
        {isTranslated ? 'Abonnieren' : 'Subskrybuj'}
      </button>
    ) : (
      <Button variant="outline-danger" className='my-5' onClick={handleShow}>
        {isTranslated ? 'Deaktivieren' : 'Deaktywuj'}
      </Button>
    )}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
      {isTranslated ? 'Es tut uns leid!' : 'Przykro nam!'}
        </Modal.Header>
        <Modal.Body>
          <p>
    {isTranslated
      ? 'Sind Sie sicher, dass Sie Ihr Abonnement kündigen möchten? Sie verlieren den Zugriff auf alle Premium-Funktionen.'
      : 'Czy na pewno chcesz dezaktywować swoją subskrypcję? Stracisz dostęp do wszystkich funkcji premium.'}
  </p>

      <button onClick={handleDeactivate} disabled={loading} name='deactivation' className='btn btn-danger'>
  {loading ?
    (isTranslated ? 'Deaktivieren...' : 'Dezaktywowanie...') :
    (isTranslated ? 'Abonnement kündigen' : 'Dezaktywuj subskrypcję')
  }
</button>

          {error && <p>{error}</p>}
          {success && <p>Subscription deactivated successfully</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
  {isTranslated ? 'Schließen' : 'Zamknij'}
</Button>

        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SubscriptionDetail;
