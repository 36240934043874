import React, { useEffect, useState, useContext, useRef } from 'react';
  import axios from 'axios';

import { useParams } from 'react-router-dom';

import axiosInstance from '../config/axiosConfig';
import { AuthContext } from '../config/AuthContext';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslate } from '../config/translateContext';
import Loader from './Loader';
import Offcanvas from 'react-bootstrap/Offcanvas';


const Forum = ({ slug, threadId }) => {
  const { messageId } = useParams();
  const messagesRef = useRef([]);
  const [commentWs, setCommentWs] = useState(null);  // Nowe połączenie dla komentarzy
  const [isCommentWsConnected, setIsCommentWsConnected] = useState(false);  // Stan połączenia komentarzy
const [forceRenderTrigger, setForceRenderTrigger] = useState(0);

  const [forumData, setForumData] = useState(null);
  const [threads, setThreads] = useState([]);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [ws, setWs] = useState(null);
  const [selectedThreadId, setSelectedThreadId] = useState(null);
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const { user, isAuthenticated, logout, hasSzukajacy, hasObrotny, profileId } = useContext(AuthContext);
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const [activeComment, setActiveComment] = useState(null);
  const [commentInput, setCommentInput] = useState({});
  const [danger, setDanger] = useState(null);
  const { isTranslated } = useTranslate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [threadTitle, setThreadTitle] = useState({
    thread_title: '',
    add_thread: true,
  });
  const [loading, setLoading] = useState(true);

  const [regulamin, setRegulamin] = useState(null);


  const [translatedTexts, setTranslatedTexts] = useState({});

  const [dangerShow, setDangerShow] = useState(false);

  const handleDangerClose = () => setDangerShow(false);
  const handleDangerShow = () => setDangerShow(true);


  const [obrotni, setObrotni] = useState([]);


  const [offcanvasShow, setOffCanvasShow] = useState(false);

  const handleOffClose = () => setOffCanvasShow(false);
  const handleOffShow = () => setOffCanvasShow(true);
  const [forumDescription, setForumDescription] = useState('');
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };






  const connectWebSocket = () => {
  const token = localStorage.getItem('access_token');

  if (!token) {
    console.warn("Brak tokena w localStorage. Użytkownik może nie być zalogowany.");
  } else {
    console.log("Token pobrany z localStorage:", token);
  }

  // Ustal URL połączenia WebSocket
  const wsBaseURL = 'obrotni.info'; // Lub 'obrotni.info' w środowisku produkcyjnym
  const wsProtocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
  const wsURL = `${wsProtocol}${wsBaseURL}/ws/forum/${slug}/?token=${encodeURIComponent(token)}`;

  // Zaloguj URL przed próbą połączenia
  console.log("URL WebSocket:", wsURL);

  const newWs = new WebSocket(wsURL);

  newWs.onopen = () => {
    console.log("Połączenie WebSocket otwarte");
    setWs(newWs);
  };

  newWs.onclose = () => {
    console.warn("Połączenie WebSocket zamknięte. Ponowna próba za 1 sekundę.");
    setTimeout(connectWebSocket, 1000);
  };

newWs.onmessage = (event) => {
  const data = JSON.parse(event.data);
  const message = data.message;
  const username = data.username || "Unknown User";


  setMessages((prevMessages) => {
    // Sprawdź, czy wiadomość o tym samym ID już istnieje w stanie
    if (!prevMessages.some((msg) => msg.id === message.id)) {
      return [...prevMessages, { ...message, username: username }];
    }
    return prevMessages;
  });
};

setWs(newWs);

};

//komentarze!



const [renderKey, setRenderKey] = useState(0);

// Funkcja do aktualizacji messages i wymuszenia renderowania
const updateMessages = (updateFunction) => {
    setMessages((prevMessages) => {
        const updatedMessages = updateFunction(prevMessages);
        return updatedMessages;
    });
    setRenderKey((prevKey) => prevKey + 1); // Zwiększ klucz, aby wymusić renderowanie
};





const connectCommentWebSocket = (messageId) => {
    const token = localStorage.getItem('access_token');
const wsProtocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
const wsBaseURL = 'obrotni.info';
const wsURL = `${wsProtocol}${wsBaseURL}/ws/comments/${messageId}/?token=${encodeURIComponent(token)}`;
const newCommentWs = new WebSocket(wsURL);


    newCommentWs.onopen = () => {
        console.log("Połączenie WebSocket dla komentarzy otwarte:", wsURL);
        setCommentWs(newCommentWs);
        setIsCommentWsConnected(true);
    };


newCommentWs.onmessage = (event) => {
    const data = JSON.parse(event.data);
    const { comment } = data;

    console.log("Otrzymano komentarz przez WebSocket:", comment);

    // Aktualizacja stanu messages
    setMessages((prevMessages) => {
        const updatedMessages = prevMessages.map((msg) => {
            if (msg.id === comment.message_id) {
                return {
                    ...msg,
                    comments: [...(msg.comments || []), comment], // Dodajemy nowy komentarz do listy
                };
            }
            return msg;
        });
        return updatedMessages;
    });

    // Wymuszenie renderowania poprzez aktualizację renderKey
    setRenderKey((prevKey) => prevKey + 1);
};











    newCommentWs.onerror = (error) => {
        console.error("Błąd WebSocket:", error);
    };

    newCommentWs.onclose = () => {
        console.log("Połączenie WebSocket dla komentarzy zamknięte.");
        setIsCommentWsConnected(false);
    };
};







useEffect(() => {
    console.log("useEffect dla activeComment uruchomiony");

    // Sprawdzenie, czy istnieje aktywny komentarz oraz czy połączenie WebSocket jest zamknięte lub nie istnieje
    if (activeComment && (!commentWs || commentWs.readyState === WebSocket.CLOSED)) {
        console.log("Tworzenie nowego połączenia WebSocket dla komentarzy:", activeComment);
        connectCommentWebSocket(activeComment);
    }

    // Zamknięcie WebSocket tylko wtedy, gdy `activeComment` się zmienia
    return () => {
        if (commentWs) {
            commentWs.close();
            console.log("Połączenie WebSocket dla komentarzy zamknięte.");
        }
    };
    // Tylko `activeComment` jako zależność
}, [activeComment]);









const sendComment = (messageId) => {
    if (commentWs && commentWs.readyState === WebSocket.OPEN) {
        const commentData = {
            content: commentInput[messageId],
            message_id: messageId,
            profil_id: profileId,
        };
        console.log("Wysyłanie komentarza przez WebSocket:", commentData);
        commentWs.send(JSON.stringify(commentData));

        // Czyszczenie pola input dla konkretnej wiadomości
        setCommentInput((prev) => ({
            ...prev,
            [messageId]: '',
        }));
    }
};










useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await axiosInstance.get(`/forum/${slug}/`);
            setForumData(response.data.forum);
            setThreads(response.data.threads);
            setForumDescription(response.data.forum.description);
            setObrotni(response.data.obrotni);
            setRegulamin(response.data.regulamin);
        } catch (error) {
            console.error('Failed to fetch forum data:', error);
        }
    };

    setLoading(true);
    fetchData();
    connectWebSocket();
    setLoading(false);

    return () => {
        if (ws && ws.readyState === WebSocket.OPEN) {
            ws.close();
        }
    };
}, []);

// Aktualizacja wiadomości w wątku
const fetchMessagesForThread = async (threadId) => {
    try {
        const response = await axiosInstance.get(`/forum/${slug}/`, {
            params: { thread: threadId },
        });
        const fetchedMessages = response.data.messages.map(msg => ({
            ...msg,
            comments: msg.comments || [],
        }));
        setMessages(fetchedMessages); // Bez używania messagesRef
    } catch (error) {
        console.error('Failed to fetch messages:', error);
    }
};

  const handleThreadSelection = (threadId) => {

    setLoading(true);
    setSelectedThreadId(threadId);
    fetchMessagesForThread(threadId);
    setLoading(false);
  };



const sendMessage = () => {
  if (ws && ws.readyState === WebSocket.OPEN && selectedThreadId) {
    const messageData = {
      content: input,
      thread_id: selectedThreadId,
      profil_id: profileId,
    };
    console.log('Sending message via WebSocket:', messageData);
    ws.send(JSON.stringify(messageData));

    setInput('');
  } else {
    console.error('WebSocket is not open or selectedThreadId is missing. Cannot send message.');
    connectWebSocket(); // Próba ponownego połączenia WebSocket, jeśli jest zamknięty
  }
};


  const handleChange = (e) => {
    const { name, value } = e.target;
    setThreadTitle({
      ...threadTitle,
      [name]: value,
    });
  };

  const handleThreadSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      try {

        const response = await axiosInstance.post(`/forum/${slug}/`, threadTitle);


        setThreads((prevThreads) => [...prevThreads, response.data]); // Dodaj nowy wątek do listy
        handleClose(); // Zamknij modal po dodaniu wątku
      } catch (error) {
        console.error('There was an error creating the thread:', error);
        if (error.response && error.response.status === 401) {
          logout(); // Wyloguj użytkownika, jeśli wystąpił błąd autoryzacji
        }
      }
    }

    setValidated(true);
    setLoading(false);
  };
const handleCommentClick = (messageId) => {
  console.log("Zmiana activeComment na:", messageId);
  setActiveComment((prevActiveComment) =>
    prevActiveComment === messageId ? null : messageId
  );
};


  const handleCommentInputChange = (messageId, value) => {
    setCommentInput((prev) => ({
      ...prev,
      [messageId]: value,
    }));
  };





  const handleReport = async (item, type) => {
    if (!item || !item.id || !item.content) {
      console.error('Invalid item data');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/report/`, {
        id: item.id,
        type: type,
        content: item.content,
        created_by: item.created_by?.user?.username || 'Unknown User',
      });

      handleDangerShow(); // Show the modal after successfully sending the report
    } catch (error) {
      console.error('Failed to send report:', error);
    }
  };



  const translateTexts = async (texts, itemId) => {
    try {


        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/translate/`, { texts: texts });

        const translatedTexts = response.data;

        setTranslatedTexts(prevTexts => ({
            ...prevTexts,
            [itemId]: {
                ...prevTexts[itemId],
                ...translatedTexts
            }
        }));

    } catch (error) {
        console.error("Translation error:", error);
    }
};

const handleTranslateAll = (item, type) => {
    const texts = {};

    if (type === 'thread') {
        texts.thread = item.title;
    } else if (type === 'post') {
        texts.post = item.content;
    } else if (type === 'comment') {
        texts.comment = item.content;
    }

    translateTexts(texts, item.id);
};



if (loading) {
  return (
    <div className='w-100 d-flex justify-content-center h-100 ontop position-fixed t-0 cloude'>
      <img src='/img/logo.png' className='rotating-object align-self-center loader' alt="Loader" />
    </div>
  );
}

  return (






    <div className="forum">
      <h1>FORUM</h1>

      <div className="container">
        <div className="row">
        <Button className='btn btn-primary' onClick={handleOffShow} >
        {isTranslated ? 'Wählen Sie einen Thread aus' : 'Wybierz wątek'}
      </Button>
      <Offcanvas show={offcanvasShow} onHide={handleOffClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>    <h3>{isTranslated ? 'Wählen Sie einen Thread aus' : 'Wybierz wątek'}</h3></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <div className="thread-list">

        {(hasSzukajacy || hasObrotny) ? (
  // Jeśli użytkownik posiada subskrypcję Szukający lub Obrotny
  <button className="purple btn" name="add_thread" onClick={handleShow}>
    <i className="bi bi-patch-plus"></i>
    {isTranslated ? 'Fügen Sie einen Thread hinzu' : 'Dodaj wątek'}
  </button>
) : (
  // Jeśli użytkownik nie ma subskrypcji
  <p className="text-danger">
    {isTranslated
      ? 'Um einen Thread hinzuzufügen, müssen Sie mindestens das Szukający-Abonnement besitzen.'
      : 'Aby dodać wątek, musisz posiadać subskrypcję przynajmniej Szukający.'}
  </p>
)}

            <div className="thread-list">





            {threads.slice().reverse().map((thread) => (
    <div  name="thread" className="thread-item d-flex justify-content-between" >
       <div className="col-9"  key={thread.id}
        onClick={() => {handleThreadSelection(thread.id); handleOffClose()}}> <p >{translatedTexts[thread.id]?.translated_thread || thread.title}</p></div>
       <div className='col-3 text-end'>
        <Button className='ki' onClick={() => handleTranslateAll(thread, 'thread')}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-translate" viewBox="0 0 16 16">
  <path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286zm1.634-.736L5.5 3.956h-.049l-.679 2.022z"/>
  <path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zm7.138 9.995q.289.451.63.846c-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6 6 0 0 1-.415-.492 2 2 0 0 1-.94.31"/>
</svg></Button>
       </div>
    </div>
))}







            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

          <div className={`discussion-window ${selectedThreadId ? 'thread-selected' : 'no-thread-selected'}`}>

            <div className="discussion-content">
            {selectedThreadId ? (<>
              <div className="discussion-scroll">
                <div className="post">
             {messages.slice().reverse().map((msg) => (
  <div key={msg.id} className=' px-3'>
    <div className="post-header ">
      <div className="post-author">
        <div className="col">
          {msg.created_by?.profilimg ? (
            <img src={`${mediaUrl}${msg.created_by.profilimg}`} alt="Profile" className='logo-forum'/>
          ) : null}
        </div>
        <div className="col">
    {msg.created_by?.user?.username || 'Unknown User'}
        </div>
      </div>
      <span className="post-date">
        <em>({formatDate(msg.created_at)})</em>
        <button
          className='btn text-danger'
          name='danger'
          onClick={() => handleReport(msg, 'message')}
        >
          <i className="bi bi-exclamation-lg text-danger meleden"></i>
          {isTranslated ? 'Melden' : 'Zgłoś'}
        </button>
      </span>
    </div>

    <div className="post-content  py-5 cloude">
    <div className='row'>
          <Button className='ki neumorphism' onClick={() => handleTranslateAll(msg, 'post')}>
     <i class="bi bi-translate"></i> {isTranslated ? 'übersetzen' : 'przetłumacz'}
      </Button>
    </div>
      <p className='my-5' key={msg.id}>
        {translatedTexts[msg.id]?.translated_post || msg.content}
      </p>

      {/* Przycisk do dodawania komentarza */}
      {(hasSzukajacy || hasObrotny) ? (
        <button
        className='btn purple mt-5'
          onClick={() => {
            console.log("Przycisk kliknięty dla msg.id:", msg.id);
            handleCommentClick(msg.id);

          }}
        >
          <i className="bi bi-patch-plus">
            {isTranslated ? 'Kommentieren' : 'Skomentuj'}
          </i>
        </button>
      ) : (
        <p className="text-danger">
          {isTranslated
            ? 'Um zu kommentieren, müssen Sie mindestens das Szukający-Abonnement besitzen.'
            : 'Aby skomentować, musisz posiadać subskrypcję przynajmniej Szukający.'}
        </p>
      )}

            {/* Pole do dodawania komentarza */}
      {activeComment === msg.id && (
        <div className="comment-input mt-2">
          <textarea
            value={commentInput[msg.id] || ''}
            onChange={(e) => handleCommentInputChange(msg.id, e.target.value)}
            rows="2"
            className="form-control"
            placeholder="Wpisz komentarz"
          ></textarea>
          <button
            className="btn btn-primary mt-2"
            onClick={() => sendComment(msg.id)}
          >
            {isTranslated ? 'Senden' : 'Wyślij'}
          </button>
        </div>
      )}









      {/* Tutaj renderujemy Accordion dla aktualnej wiadomości */}
      <Accordion key={`accordion-${msg.id}-${renderKey}`} className="my-3 this-acc">
        <Accordion.Item eventKey={`${msg.id}`}>
          <Accordion.Header>
            {msg.comments && msg.comments.length > 0
              ? isTranslated ? 'Kommentare' : 'Komentarze'
              : isTranslated ? 'Kein Kommentaren' : 'Nie ma komentarzy'}
          </Accordion.Header>
          {msg.comments && msg.comments.length > 0 && (
            <Accordion.Body>
              {msg.comments.slice().reverse().map((comment) => (
                <div key={`comment-${comment.id}`} className="comment row">
                  <p><strong>{comment.created_by}</strong></p>
                  <p>{comment.content}</p>
                </div>
              ))}
            </Accordion.Body>
          )}
        </Accordion.Item>
      </Accordion>



    </div>
  </div>
))}



                </div>

              </div>


{(hasObrotny || hasSzukajacy) && (
  <div className="reply-form">
    <div className='lets-reply'>
      <textarea
        value={input}
        onChange={(e) => setInput(e.target.value)}
        rows="2"
      ></textarea>
      <button className='btn-forum' onClick={sendMessage}>
        {isTranslated ? 'Senden' : 'Wyślij'}<i className="bi bi-send"></i>
      </button>
    </div>
  </div>
)}


                        </>
                  ) : (
                    <div className="forum-description">
                      <div dangerouslySetInnerHTML={{ __html: forumDescription }} />


                    </div>
                  )}

            </div>

          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
        <Modal.Title>{isTranslated ? 'Thema im Forum hinzufügen' : 'Dodaj wątek do forum'}</Modal.Title>

        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleThreadSubmit}>
            <Row className="mb-5">
              <Form.Group as={Col} md="12" controlId="validationCustom01">
              <Form.Label>{isTranslated ? 'Thema benennen' : 'Zatytułuj wątek'}</Form.Label>

                <Form.Control required type="text" placeholder="..." name="thread_title" onChange={handleChange} />
              </Form.Group>
            </Row>
            <Button type="submit" className="purple">
  {isTranslated ? 'Hinzufügen' : 'Dodaj'}
</Button>

          </Form>
        </Modal.Body>
      </Modal>





      <Modal show={dangerShow} onHide={handleDangerClose} centered>
        <Modal.Header closeButton>
        <Modal.Title>{isTranslated ? 'Danke für Ihre Wachsamkeit!' : 'Dziękujemy za czujność!'}</Modal.Title>

        </Modal.Header>
        <Modal.Body>{isTranslated ? 'Wir werden prüfen, ob dieser Kommentar gegen die Regeln verstößt!' : 'Sprawdzimy czy ten komentarz narusza zasady!'}</Modal.Body>


      </Modal>
<div className='row regulamin'>
        <div className='col-md-12'>
          <p>  <strong className='text-danger'>
    {isTranslated
      ? 'Mit Ihrer Teilnahme an der Diskussion im Forum akzeptieren Sie die Regeln!'
      : 'Biorąc udział w dyskusji na forum akceptujesz jego regulamin!'}
  </strong></p>
        <Accordion>
      <Accordion.Item eventKey="3">
        <Accordion.Header>
  {isTranslated ? 'Forumsregeln' : 'Regulamin forum'}
</Accordion.Header>

        <Accordion.Body>

        {regulamin ? (
      <div className='animatable my-5' dangerouslySetInnerHTML={{ __html: regulamin.text }} />
    ) : (
      <p>Ładowanie regulaminu...</p> // Możesz wyświetlić komunikat ładowania
    )}
            </Accordion.Body>
      </Accordion.Item>

    </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Forum;
